var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "game-container",
    },
    [
      _c("game-prize-modal", {
        attrs: {
          "game-prize": _vm.gamePrize,
          share: _vm.data.share,
          "do-share": _vm.doShare,
          "friend-url": _vm.data.friendUrl,
        },
      }),
      _vm._v(" "),
      _c("game-fail-modal", {
        attrs: {
          "content-type": _vm.contentType,
          "binding-url": _vm.data.bindingUrl,
        },
      }),
      _vm._v(" "),
      _c("button", {
        attrs: {
          type: "button",
          id: "fail_btn",
          "data-bs-target": "#gameFailModal",
          "data-bs-toggle": "modal",
          hidden: "",
        },
      }),
      _vm._v(" "),
      _vm.data.status == "未開始"
        ? [_vm._m(0)]
        : _vm.data.status == "已結束"
        ? [_vm._m(1)]
        : _vm.data.status == "無權限"
        ? [_vm._m(2)]
        : _vm.data.status == "外部瀏覽器"
        ? [_vm._m(3)]
        : [
            _c(
              "div",
              {
                style: [
                  _vm.gameStyle.backgroundColorType == "default"
                    ? {
                        "background-color": _vm.getDefaultBgc(
                          _vm.gameStyle.mainColor
                        ),
                      }
                    : { "background-color": _vm.gameStyle.backgroundColor },
                ],
              },
              [
                _c("div", { staticClass: "game-content" }, [
                  _c("div", { staticClass: "game-content-fix" }, [
                    _c(
                      "div",
                      { staticClass: "game-content-img" },
                      [
                        _c("img", {
                          style: [
                            _vm.gameStyle.backgroundImagePath &&
                            _vm.gameStyle.backgroundImagePath != ""
                              ? { visibility: "hidden" }
                              : {},
                          ],
                          attrs: {
                            src: _vm.getBackgroundImage(),
                            alt: "遊戲背景圖",
                          },
                        }),
                        _vm._v(" "),
                        _vm.gameStyle.backgroundImagePath &&
                        _vm.gameStyle.backgroundImagePath != ""
                          ? _c("img", {
                              staticStyle: { position: "absolute" },
                              attrs: {
                                src: _vm.gameStyle.backgroundImagePath,
                                alt: "遊戲背景圖",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("game-anime", {
                          attrs: {
                            "is-drawing": _vm.isDrawing,
                            "main-color": _vm.gameStyle.mainColor,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.data.share,
                                expression: "data.share",
                              },
                            ],
                            staticClass: "game-content-share text-size-14",
                            on: {
                              click: function ($event) {
                                _vm.doShare()
                              },
                            },
                          },
                          [_vm._m(4), _vm._v(" 分享遊戲\n                ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "game-content-btn",
                        style: [
                          {
                            "background-color": _vm.getDefaultBtnStyle(
                              _vm.gameStyle.mainColor
                            )["background-color"],
                          },
                        ],
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "text-size-22 text-bold",
                            style: _vm.getDefaultBtnStyle(
                              _vm.gameStyle.mainColor
                            ),
                            attrs: {
                              type: "button",
                              disabled: _vm.isDrawing || !_vm.isQualified,
                            },
                            on: { click: _vm.doDraw },
                          },
                          [_vm._v(_vm._s(_vm.gameStyle.buttonName))]
                        ),
                        _vm._v(" "),
                        _c("button", {
                          attrs: {
                            id: "prize_btn",
                            "data-bs-target": "#gamePrizeModal",
                            "data-bs-toggle": "modal",
                            hidden: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { padding: "20px" } }, [
                  _c("div", { staticClass: "game-content-rule" }, [
                    _c(
                      "div",
                      { staticClass: "game-content-rule-block" },
                      [
                        _vm.data.frequency == "每人"
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center text-size-14 text-bold",
                                },
                                [
                                  _vm._v(
                                    "\n                            剩餘遊戲次數：" +
                                      _vm._s(
                                        _vm.data.remainTimes
                                          ? _vm.data.remainTimes
                                          : 0
                                      ) +
                                      "次\n                        "
                                  ),
                                ]
                              ),
                            ]
                          : _vm.data.frequency == "每日"
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center text-size-14 text-bold",
                                },
                                [
                                  _vm._v(
                                    "\n                            今日剩餘遊戲次數：" +
                                      _vm._s(
                                        _vm.data.remainTimes
                                          ? _vm.data.remainTimes
                                          : 0
                                      ) +
                                      "次\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center text-size-14 text-grey-3",
                                },
                                [
                                  _vm._v(
                                    "\n                            每日 " +
                                      _vm._s(_vm.data.dailyResetAt) +
                                      " 重新計算遊戲次數\n                        "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "game-content-rule-block" }, [
                      _c(
                        "div",
                        { staticClass: "text-center text-size-14 text-bold" },
                        [
                          _vm._v(
                            "\n                        遊戲活動期間\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center text-size-14" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.data.startAt.replace(/:00$/, "")) +
                            " 至 " +
                            _vm._s(_vm.data.endAt.replace(/:00$/, "")) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "game-content-rule-block" },
                      [
                        _vm.gameStyle.rule && _vm.gameStyle.rule != ""
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center text-size-14 text-bold",
                                },
                                [_vm._v("規則說明")]
                              ),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.gameStyle.rule) +
                                  "\n                    "
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.gameStyle.prizeImagePath
                          ? [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "text-align": "center",
                                    "margin-top": "20px",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.gameStyle.prizeImagePath,
                                      alt: "獎項圖",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "game-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/game/game_over.png",
          alt: "遊戲已結束",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "game-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("活動尚未開始"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [_vm._v("敬請期待")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "game-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/game/game_over.png",
          alt: "遊戲已結束",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "game-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("活動已結束"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [
          _vm._v("敬請期待我們的下一個活動"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "game-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/prize/failed.png",
          alt: "無法開啟此遊戲",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "game-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("Sorry"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [_vm._v("無法開啟此遊戲")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "game-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/prize/failed.png",
          alt: "無法開啟此遊戲",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "game-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("Sorry"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [
          _vm._v("請使用 LINE 瀏覽器開啟"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticStyle: { transform: "rotateY(180deg)" } }, [
      _c("i", { staticClass: "bx bx-share" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }