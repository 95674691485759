<template>
    <div>
        <template v-if="status == 'isMember'">
            <member-card :liff-id="liffId" :member="member"  ></member-card>
        </template>
        <template v-else-if="status == 'isNotMember'">
            <member-register :liff-id="liffId" :add-friend-url="addFriendUrl" :game-url="gameUrl" ></member-register>
        </template>
    </div>
</template>

<script>
import MemberRegister from "@/components/member/MemberRegister";
import MemberCard from "@/components/member/MemberCard";
import { getLineToken, getLiffId } from '@/utils/auth'
import { getMember } from "@/api/member";  
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
    metaInfo() {
        return {
            title: this.pageTitle,
        };
    },
    components: {
        'member-register': MemberRegister,
        'member-card': MemberCard
    },
    data() {
        return {
            liffId: null,
            idToken: null,
            member: null,
            addFriendUrl: '',
            status: '',
            gameUrl: null,
            pageTitle: ''
        }
    },
    created() {
        // 判斷是否為會員
        this.liffId = getLiffId()
        this.idToken = getLineToken()
        
        // 判斷是否從遊戲過來綁定的
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const rg = urlParams.get('rg')
        if (rg && rg != '') {
            this.gameUrl = rg
        } 
        getMember(this.liffId, this.idToken).then((res) => {

            if (res) {

                this.addFriendUrl = res.friendUrl


                // 沒關注就直接導到關注
                if (!res.isFollow && this.addFriendUrl) {
                    window.location.href = this.addFriendUrl
                    return
                }
                if (res.memberId) {
                    this.status = 'isMember'
                    this.member = res
                } else {
                    this.status = 'isNotMember'
                }

                if (res.lineAccountName) {
                    this.pageTitle = res.lineAccountName
                }
            }
        })
    }
}
</script>
