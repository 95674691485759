var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "gameFailModal",
        tabindex: "-1",
        "aria-labelledby": "gameFailModalLabel",
        "aria-hidden": "true",
        "data-bs-backdrop": "static",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-sm" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.contentType == "noLotteryTimes"
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: {
                            type: "button",
                            "data-bs-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        ×\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(0),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.contentType == "noTodayLotteryTimes"
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: {
                            type: "button",
                            "data-bs-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        ×\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(1),
                    ]
                  : _vm.contentType == "noBinding"
                  ? [
                      _c("div", { staticClass: "text-center" }, [
                        _c("div", { staticClass: "text-size-16" }, [
                          _vm._v("完成綁定後即可玩遊戲"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary binding-btn text-bold",
                            on: {
                              click: function ($event) {
                                _vm.toBinding()
                              },
                            },
                          },
                          [_vm._v("前往綁定")]
                        ),
                      ]),
                    ]
                  : _vm.contentType == "lineBrowserError"
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: {
                            type: "button",
                            "data-bs-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        ×\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(2),
                    ]
                  : _vm.contentType == "internalError"
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: {
                            type: "button",
                            "data-bs-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        ×\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(3),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "text-bold" }, [_vm._v("您的遊戲次數已經用完")]),
      _vm._v(" "),
      _c("div", { staticClass: "text-size-14 text-grey-3" }, [
        _vm._v("感謝您的參與，"),
        _c("br"),
        _vm._v("\n                        敬請期待我們的下一個活動"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "text-bold" }, [
        _vm._v("您今日的遊戲次數已經用完"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-size-14 text-grey-3" }, [
        _vm._v("請明天再來挑戰，獲取更多中獎機會！"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "text-size-16" }, [
        _vm._v("請使用 LINE APP 完成分享"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "text-bold" }, [_vm._v("Oops...出了一點狀況")]),
      _vm._v(" "),
      _c("div", { staticClass: "text-size-14 text-grey-3" }, [
        _vm._v("請稍後再試"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }