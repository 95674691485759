var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "binding-content" }, [
      _c("div", { staticClass: "binding-content-text" }, [
        _vm._v("\n             姓名"),
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
        _c("br"),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.member.officialName,
              expression: "member.officialName",
            },
          ],
          staticClass: "form-control",
          class: _vm.status(_vm.$v.member.officialName),
          attrs: { type: "text", placeholder: "請輸入姓名" },
          domProps: { value: _vm.member.officialName },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.member, "officialName", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "binding-content" }, [
      _c("div", { staticClass: "binding-content-text" }, [
        _vm._v("\n             手機號碼"),
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
        _c("br"),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.member.memberId
                ? _vm.member.phone
                : "09" + _vm.member.phone,
              expression:
                " member.memberId ? member.phone : '09' + member.phone",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", disabled: "" },
          domProps: {
            value: _vm.member.memberId
              ? _vm.member.phone
              : "09" + _vm.member.phone,
          },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.member.memberId ? _vm.member.phone : "09" + _vm.member,
                "phone",
                $event.target.value
              )
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "binding-content" }, [
      _c(
        "div",
        { staticClass: "binding-content-text" },
        [
          _vm._v("\n             生日"),
          _c("span", { staticClass: "required" }, [_vm._v("*")]),
          _c("br"),
          _vm._v(" "),
          _c(
            "date-picker",
            {
              class: _vm.status(_vm.$v.member.birthday),
              staticStyle: { width: "100%" },
              attrs: {
                "input-class": "form-control",
                clearable: true,
                range: false,
                type: "date",
                "value-type": "YYYY-MM-DD",
                format: "YYYY-MM-DD",
                placeholder: "請選擇",
                editable: false,
              },
              model: {
                value: _vm.member.birthday,
                callback: function ($$v) {
                  _vm.$set(_vm.member, "birthday", $$v)
                },
                expression: "member.birthday",
              },
            },
            [
              _c("i", {
                attrs: { slot: "icon-calendar" },
                slot: "icon-calendar",
              }),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "binding-content" }, [
      _c("div", { staticClass: "binding-content-text" }, [
        _vm._v("\n             性別"),
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
        _c("br"),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.member.gender,
                expression: "member.gender",
              },
            ],
            staticClass: "form-control form-select",
            class: _vm.status(_vm.$v.member.gender),
            style: [
              _vm.member.gender == ""
                ? { color: "#999999" }
                : { color: "#333333" },
            ],
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.member,
                  "gender",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { attrs: { value: "", disabled: "" } }, [
              _vm._v("請選擇"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "男" } }, [_vm._v("男")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "女" } }, [_vm._v("女")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "其他" } }, [_vm._v("其他")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "binding-content" }, [
      _c("div", { staticClass: "binding-content-text" }, [
        _vm._v("\n             Email"),
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
        _c("br"),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.member.email,
              expression: "member.email",
            },
          ],
          staticClass: "form-control",
          class: _vm.status(_vm.$v.member.email),
          attrs: { type: "text", placeholder: "請輸入電子郵件" },
          domProps: { value: _vm.member.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.member, "email", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "binding-content" }, [
      _c("div", { staticClass: "binding-content-text" }, [
        _vm._v("\n             地址"),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "binding-content-district" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.member.addCity,
                  expression: "member.addCity",
                },
              ],
              staticClass: "form-control form-select",
              style: [
                _vm.member.addCity == ""
                  ? { color: "#999999" }
                  : { color: "#333333" },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.member,
                    "addCity",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("請選擇城市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "臺北市" } }, [_vm._v("臺北市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "新北市" } }, [_vm._v("新北市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "基隆市" } }, [_vm._v("基隆市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "新竹市" } }, [_vm._v("新竹市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "桃園市" } }, [_vm._v("桃園市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "新竹縣" } }, [_vm._v("新竹縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "宜蘭縣" } }, [_vm._v("宜蘭縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "臺中市" } }, [_vm._v("臺中市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "苗栗縣" } }, [_vm._v("苗栗縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "彰化縣" } }, [_vm._v("彰化縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "南投縣" } }, [_vm._v("南投縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "雲林縣" } }, [_vm._v("雲林縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "高雄市" } }, [_vm._v("高雄市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "臺南市" } }, [_vm._v("臺南市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "嘉義市" } }, [_vm._v("嘉義市")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "嘉義縣" } }, [_vm._v("嘉義縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "屏東縣" } }, [_vm._v("屏東縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "澎湖縣" } }, [_vm._v("澎湖縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "花蓮縣" } }, [_vm._v("花蓮縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "臺東縣" } }, [_vm._v("臺東縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "金門縣" } }, [_vm._v("金門縣")]),
              _vm._v(" "),
              _c("option", { attrs: { value: "連江縣" } }, [_vm._v("連江縣")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.member.addDistrict,
                  expression: "member.addDistrict",
                },
              ],
              staticClass: "form-control form-select",
              staticStyle: { "margin-left": "auto" },
              style: [
                _vm.member.addDistrict == ""
                  ? { color: "#999999" }
                  : { color: "#333333" },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.member,
                    "addDistrict",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("請選擇區域")]),
              _vm._v(" "),
              _vm._l(_vm.selectDistricts, function (item, index) {
                return _c("option", { domProps: { value: item } }, [
                  _vm._v(_vm._s(item)),
                ])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.member.addStreet,
              expression: "member.addStreet",
            },
          ],
          staticClass: "form-control",
          staticStyle: { "margin-top": "4px" },
          attrs: { type: "text", placeholder: "詳細地址" },
          domProps: { value: _vm.member.addStreet },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.member, "addStreet", $event.target.value)
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }