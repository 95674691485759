import Cookies from 'js-cookie'
import SHA256 from 'crypto-js/sha256';

const lineTokenKey = 'tokenKey'
const authTokenKey = 'DTC_C360PLUS_LIFF_TOKEN_BASE'
const liffIdKey = 'liffId'
const getCurrentDate = () => {
  const now = new Date();
  
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是從0開始的
  const day = String(now.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

export function getAuthToken() {
  return SHA256(authTokenKey + '_' + getCurrentDate()).toString()
}


export function getLineToken() {
  return Cookies.get(lineTokenKey)
}

export function getLiffId() {
  return Cookies.get(liffIdKey)
}
  
export function setToken(token) {
  return Cookies.set(lineTokenKey, token, {sameSite: 'None', secure: true})
}

export function setLiffId(liffId) {
  return Cookies.set(liffIdKey, liffId, {sameSite: 'None', secure: true})
}