// The Vue build version to load with the import command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import store from './store'
import App from './App'
import router from './router'
// import '@/permission' // permission control

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-TW' // lang i18n

// datepicker
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

// meta-data
import VueMeta from 'vue-meta';
// Bootstrap
// import 'bootstrap/dist/css/bootstrap.css'
// import * as bootstrap from "bootstrap/dist/js/bootstrap.js"
import Popper from 'popper.js'

import Vuelidate from 'vuelidate'


import 'boxicons';
import '@/permission'; // permission control

Vue.use(DatePicker)
Vue.use(ElementUI, { locale }, Popper)

Vue.use(Vuelidate);
Vue.use(VueMeta)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
