import Vue from 'vue'
import Vuex from 'vuex'
import { setToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null
  },
  actions: {
    resetToken(context, status) {
        context.commit('RESET_TOKEN', status)
        setToken(status)     
    },
  },
  mutations: {
    RESET_TOKEN(state, status) {      //status -> payload （載荷）
        state.token = status;
    }
  },
  getters: {
    token: state => {
        return state.token
    }
  }
})