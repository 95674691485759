<template>
    <div class="modal fade" id="gamePrizeModal" tabindex="-1" 
        aria-labelledby="gamePrizelLabel" aria-hidden="true">
        
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="game-prize text-center">
                    <img :src="gamePrize.imagePath" alt="">
                    <div class="game-prize-message text-size-24 text-grey-1 text-bold">
                        {{gamePrize.message}}
                    </div>
                    <div class="game-prize-name ">
                        {{gamePrize.prizeName}}
                    </div>
                    <div class="game-prize-hint text-size-14 text-grey-2" v-show="gamePrize.prizeName != '未中獎'">
                        獎項已發送，請前往聊天室查看
                    </div>
                    <div class="game-prize-btns">
                        <button class="btn btn-primary text-size-14" @click="toChatRoom()">前往聊天室</button>
                        <button class="btn text-blue text-size-14" @click="doShare" v-show="share"><i class='bx bx-share'></i>&nbsp;分享遊戲給好友</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
</template>

<script>

export default {
    props: ['gamePrize', 'share', 'doShare', 'friendUrl'],
    methods: {
        toChatRoom() {
            if (this.friendUrl) {
                window.location.href = this.friendUrl
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
.modal-dialog {
    width: 80%;
    margin: auto;
}

.modal-content {
    border-radius: 16px;
}

.game-prize {
    padding: 20px;
}

.game-prize img {
    width: 100%;
}

.game-prize-message {
    margin-top: 20px;
}
.game-prize-hint {
    margin: 20px;
}
.game-prize-btns {
    margin-top: 20px;
}

.game-prize-btns button {
    width: calc(100% - 80px);;
    border-radius: 20px;
    border: 1px solid #3267E3;
    margin-bottom: 10px; 
}

</style>
