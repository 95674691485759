<template>
  <div class="wrap">
    <div class="binding" v-loading="loading">
      <binding-error-modal :error-text="errorText"></binding-error-modal>
      <button id="bindingErrBtn" data-bs-toggle="modal" data-bs-target="#bindingErrModal" hidden></button>


      <div class="modal fade" id="privacyModal" tabindex="-1" 
        aria-labelledby="privacyModalLabel" aria-hidden="true"
        data-bs-backdrop="static" >
        
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header ">
                <h6 class="modal-title" id="privacyModalLabel">個人資料聲明同意書</h6>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                
                <div class="privacy-block" v-html="setting.privacy"></div>
            </div>
            <!-- <div class="modal-footer">
                <button class="btn"  data-bs-dismiss="modal">取消</button>
                <button class="btn btn-danger"  data-bs-dismiss="modal" @click="$emit('do-delete')">刪除</button>
            </div> -->
            
            </div>
        </div>
      </div>


      <div
        class="binding-header"
        :style="setting.header"
      >
        綁定會員
      </div>
      <template v-if="page == 1">
        <div class="binding-content">
          <div class="binding-content-text">手機號碼</div>
          <div class="binding-content-phone">
              09&nbsp;
            <input style="width: 40%;
              border: none;
              outline: none;
              margin-top: 2px;" 
              type="tel" v-model="member.phone" oninput="this.value=this.value.slice(0,8)" placeholder="12345678">
            <button class="binding-content-resend btn" :style="setting.button" :disabled="member.phone.length < 8 || isSend " 
            @click="sendOTP" >發送驗證碼</button>
          </div>
          <div class="binding-content-text" style="text-align: right" :style="[isSend ? {'opacity': '1'} : {'opacity': '0'}]">
            <span style="color: #3267e3">{{ second }}</span> 秒後重新發送
          </div>
          <div class="binding-content-text">驗證碼</div>
          <div class="binding-content-otp">
            <input 
              v-for="(item, index) in otpValues" 
              class="binding-content-otp-block"
              :key="index" 
              type="tel"
              v-model="otpValues[index]"
              @keyup="handleKeyup(index)"
              :ref="'input' + index"
            />
            
          </div>
          <div class="binding-content-otp-hint" :style="[isSend ? {'opacity': '1'} : {'opacity': '0'}]">請注意，驗證碼在發送後的 5 分鐘內有效</div>
          <button
            class="binding-content-btn btn"
            :style="setting.button"
            :disabled="checkOtpVal" @click="checkOTP">
            送出
          </button>
        </div>
      </template>

      <template v-if="page == 2">
        <member-info ref="MemberInfo" :member="member" :city-district="cityDistrict" :is-check="isCheck"></member-info>

        <div class="binding-content">

          <div class="binding-content-privacy">
            
            <label for="privacy" class="checkbox-container">
              <input id="privacy" type="checkbox" v-model="member.isAgree" />
               <span class="checkmark"></span>
              <span>我已閱讀並同意 <a href="#privacyModal" data-bs-toggle="modal">個人資料聲明同意書</a></span>
            </label>
          </div>
          <button
              class="binding-content-btn btn"
              :style="setting.button"
              :disabled="!member.isAgree"
              @click="checkMemberInfo">
              送出
            </button>
        </div>
        <city-district @city-district="getCityDistrict" />
      </template>

      <template v-if="page == 3">
        <div class="binding-content">
          <div class="binding-content-success">
            <div><i class='bx bxs-check-circle bx-md'></i></div>
            <div>綁定成功</div>
          </div>
        </div>
        <div class="binding-content">
        
        <template v-if="gameUrl && gameUrl != ''">
          <a class="binding-content-btn btn" :style="setting.button" :href="gameUrl"
             >開始玩遊戲 </a>
        </template>
        <template v-else>
          <a class="binding-content-btn btn" :style="setting.button" :href="addFriendUrl"
             >返回至 LINE </a>
        </template>
        </div>
      </template>
    </div>
    
    
  </div>
</template>

<script>

import $ from "jquery";
import CityDistrict from "@/components/CityDistrict.vue";
import BindingErrorModal from "@/components/modal/BindingErrorModal.vue";
import { getBindingSetting, doSendOtp, doVerify, doBinding } from "@/api/member"; 
import { getLineToken } from "@/utils/auth"; 
import MemberInfo from './MemberInfo.vue';

export default {
    name: 'member-register',
    props: ['liffId', 'addFriendUrl', 'gameUrl'],
    components: {
      'city-district': CityDistrict,
      'binding-error-modal': BindingErrorModal,
      'member-info': MemberInfo
    },
    computed: {
      checkOtpVal: function () {
        for (let n of this.otpValues) {
          if (n == '') {
            return true
          }
        }

        return false
      },
      getHeaderStyle: function () {
        return this.setting.header
      },
      getBtnStyle: function () {
        return this.setting.button
      }
    },
    created() {
      this.idToken = getLineToken()
      this.fetchData()
    },
    watch: {
      otpValues: {
        handler: function (newValue, oldValue) {
          for (let i = 0; i < 4; i++) {

            if (newValue[i].length > 0 && newValue[i].length <= 3) {
              this.otpValues[i] = this.otpValues[i].slice(0, 1) 
            } else if (newValue[i].length > 3) {
              this.handleChange(i)
            }
          }
        },
        deep: true,
      },
    },
    data() {
        return {
            page: 1,
            loading: false,
            idToken: null,
            isCheck: false,
            errorText: '',
            setting: {
                header: {
                    background: "#FFFFFF",
                    color: "#FFFFFF",
                },
                button: {
                    background: "#FFFFFF",
                    color: "#FFFFFF",
                },
                privacy: ""
            },
            member: {
                officialName: '',
                phone: '',
                birthday: '',
                gender: '',
                email: '',
                addCity: '',
                addDistrict: '',
                addStreet: '',
                isAgree: false
            },
            isSend: false,
            otpValues: ['', '', '', ''],
            second: 90,
            cityDistrict: null
        }
    },
    methods: {
      getCityDistrict(data) {
        this.cityDistrict = data
      },
      fetchData() {
        getBindingSetting(this.liffId).then((res) => {
          if (res.setting) {
            let setting = JSON.parse(res.setting);

            // 預設藍色
            if (!setting.header.background) {
              setting.header.background = '#3267E3'
            }
            if (!setting.button.background) {
              setting.button.background = '#3267E3'
            }
            
            this.setting = setting

          } else {
            this.setting.header.background = '#3267E3'
            this.setting.button.background = '#3267E3'
          }
        })
      },
      handleKeyup(index) {
        if (this.otpValues[index].length > 0 && index < this.otpValues.length - 1) {
          this.$refs['input' + (index + 1)][0].focus();
        } else if (this.otpValues[index].length == 0 && index != 0) {
          this.$refs['input' + (index - 1)][0].focus();
        }
      },
      handleChange(index) {

        if (this.otpValues[index].length > 3) {
          let value = this.otpValues[index].substring(0, 4)
          const digits = value.split('').filter(c => !isNaN(c) && c !== ' ');

          if (digits.length === 4) {
            this.otpValues = digits;
            this.$refs['input3'][0].focus()
          }
          
        }
      },
      status(validation) {
        return {
          'is-invalid': (validation.$error || validation.$invalid) && this.isCheck,
          dirty: validation.$dirty,
        }
      },
      async sendOTP() {
        
        
        this.isSend = true
        let that = this
        const timer = setInterval(function(){
          that.second -- ;
          if(that.second <= 0 ) {
            that.isSend = false
            that.second = 90
            clearInterval(timer);
          }
        }, 1000);

        let data = {
          phone: '09' + this.member.phone,
          lineToken: this.idToken,
          settingLiffId: this.liffId
        }
        await doSendOtp(this.liffId, data)
        

      },
      checkOTP() {
        
        if (this.member.phone.length < 8) {
          this.errorText = '手機號碼格式錯誤，請再次輸入'
          $('#bindingErrBtn').click()
          return
        }

        // 判斷OTP是否正確
        let otp = ''
        for (let i = 0; i < 4; i++) {
          otp += this.otpValues[i]
        }

        let data = {
          code: otp,
          phone: '09' + this.member.phone,
          settingLiffId: this.liffId,
          token: this.idToken
        }

        this.loading = true
        doVerify(this.liffId, data).then((res) => {
          this.loading = false
          if (res.isSuccess) {
            if (res.isValid) {
              this.page = 2
            } else {
              this.errorText = '驗證失敗，請再次輸入'
              $('#bindingErrBtn').click()
            }
          }
        })

      },
      checkMemberInfo() {

        this.isCheck = true
        if (this.$refs.MemberInfo.$v.$invalid || this.$refs.MemberInfo.$v.$error) {
          return
        }
        

        // 檢查email格式
        let email = this.member.email
        if (!this.isEmail(email)) {
          this.errorText = 'Email格式錯誤，請再次輸入'
          $('#bindingErrBtn').click()
          return
        }

        if (this.isNotEmpty(this.member.addCity) || this.isNotEmpty(this.member.addDistrict) || this.isNotEmpty(this.member.addStreet)) {
          
          let hasEmpty = !(this.isNotEmpty(this.member.addCity) && this.isNotEmpty(this.member.addDistrict) && this.isNotEmpty(this.member.addStreet))
          if (hasEmpty) {
            this.errorText = '請填寫完整地址資訊'
            $('#bindingErrBtn').click()
            return
          }
        }

        let member = this.member
        let data = {
          lineToken: this.idToken,
          bindingInfo: {
            phone: '09' + member.phone,
            email: member.email,
            gender: member.gender,
            birthday: member.birthday,
            officialName: member.officialName,
            addCity: member.addCity,
            addDistrict: member.addDistrict,
            addStreet: member.addStreet,
            settingLiffId: this.liffId
          }
        }

        console.log(data)

        this.loading = true
        doBinding(this.liffId, data).then((res) => {
          this.loading = false
          if (res.isSuccess) {
            this.page = 3
          } else {
            this.$message({
              message: '綁定失敗，請洽技術人員',
              type: 'warning'
            })
          }
        }).catch((err) => {
          this.$message({
              message: '綁定失敗，請洽技術人員',
              type: 'warning'
          })
          return err
        })

      },
      isEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true
        } else {
            return false
        }
      },
      isNotEmpty(value) {
        return value != null && value != ''
      },
    }
};
</script>

<style scoped>

.wrap {
    max-width: 768px;
    margin: auto;
}


.form-control::placeholder {
  color: #999999;
}
.btn:disabled {
  background-color: #F4F4F4 !important;
  color: #999999 !important;
}
.btn:focus,
.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.modal-header {
  background-color: #F4F4F4;
  color: #333333;
  justify-content: center;
}
.modal-header .close {
  border: none;
  background-color: #F4F4F4;
  position: absolute;
  top: 5px;
  right: 3px;
  color: #333333;
  font-size: 20px;
}

.modal-dialog {
  margin: 15px;
}

::v-deep .modal-content img {
  width: 100%;
}

.binding {
  padding-bottom: 15px;
}
.binding-header {
  font-size: 20px;
  font-weight: 500;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.binding-content {
  padding: 36px 47.5px 0 47.5px;
}
.binding-content-phone {
  border: 1px solid #E7E9ED;
  border-radius: 8px;
  display: flex;
  padding: 7px 12px;
  align-items: center;
  color: #333333;
}

.binding-content-phone input,
.binding-content-phone input:focus,
.binding-content-phone input:focus-visible {
    width: 40%;
    border: none;
    outline: none;
}

.binding-content-resend {
  margin-left: auto;
  /* background-color: #F4F4F4; */
  font-size: 14px;
  padding: 8px 10px;
  /* color: #999999; */
  border-radius: 4px;
}



.binding-content-text {
  font-size: 14px;
  margin-bottom: 4px;
  color: #333333;
}
.binding-content-otp {
  display: flex;
  justify-content: space-between;
}

.binding-content-otp-hint {
  color: #3267E3;
  font-size: 14px;
  margin-bottom: 80px;
}


.binding-content-otp-block {
  width: 60px;
  height: 60px;
  border: 1px solid #E7E9ED;
  border-radius: 8px;
  color: #333333;
  font-size: 20px;
  text-align: center;
}

.binding-content-otp-block:focus {
  border: 1px solid #3267E3;
;
}

.binding-content-btn {
  text-align: center;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
}

.binding-content-privacy {
  text-align: center;
  font-size: 14px;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.binding-content-success {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #3267E3;
  margin: 100px 0;
}

.binding-content-success i {
  color: #22C55E;
}




</style>