import Vue from 'vue'
import Router from 'vue-router'
import Member from '@/views/member/index.vue'
import Prize from '@/views/prize/index.vue'
import Game from '@/views/game/index.vue'
import NotFound from '@/views/404.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/member/:liffId',
      name: 'Member',
      component: Member,
    },
    {
      path: '/prize/:liffId',
      name: 'Prize',
      component: Prize,
    },
    {
      path: '/game/:liffId',
      name: 'Game',
      component: Game,
    },


    {
      path: '/404',
      component: NotFound,
      hidden: true
    },

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }
  ]
})
