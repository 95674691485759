var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "bindingErrModal",
        tabindex: "-1",
        "aria-labelledby": "bindingErrModallLabel",
        "aria-hidden": "true",
        "data-bs-backdrop": "static",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-sm" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "error-content" }, [
              _c("div", { staticClass: "error-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.errorText) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        { staticClass: "error-btn btn", attrs: { "data-bs-dismiss": "modal" } },
        [_vm._v("了解")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }