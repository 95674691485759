var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "gamePrizeModal",
        tabindex: "-1",
        "aria-labelledby": "gamePrizelLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-sm" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "game-prize text-center" }, [
              _c("img", { attrs: { src: _vm.gamePrize.imagePath, alt: "" } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "game-prize-message text-size-24 text-grey-1 text-bold",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.gamePrize.message) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "game-prize-name " }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.gamePrize.prizeName) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.gamePrize.prizeName != "未中獎",
                      expression: "gamePrize.prizeName != '未中獎'",
                    },
                  ],
                  staticClass: "game-prize-hint text-size-14 text-grey-2",
                },
                [
                  _vm._v(
                    "\n                    獎項已發送，請前往聊天室查看\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "game-prize-btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary text-size-14",
                    on: {
                      click: function ($event) {
                        _vm.toChatRoom()
                      },
                    },
                  },
                  [_vm._v("前往聊天室")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.share,
                        expression: "share",
                      },
                    ],
                    staticClass: "btn text-blue text-size-14",
                    on: { click: _vm.doShare },
                  },
                  [
                    _c("i", { staticClass: "bx bx-share" }),
                    _vm._v(" 分享遊戲給好友"),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }