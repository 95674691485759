<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css" ></style>
<style src="@/assets/css/common.css" ></style>
<style>
#app {
  font-family: "PingFang SC", "Microsoft JhengHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.tooltip-inner {
  max-width: 100%;
  text-align: left;
  background-color: #4f5055;
}

.multiselect__tag {
  color: #000;
  background-color: #E0E7F0;
}

.is-invalid input, .is-invalid svg {
  border-color: #dc3545 !important;
  color: #dc3545;
}

.is-invalid input:focus {
  outline-color: #dc3545 !important;
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
}

.is-invalid .multiselect__tags {
  border-color: #dc3545 !important;
  color: #dc3545;
}

.is-invalid .multiselect__select:before {
  border-color: #dc3545 transparent transparent !important;
  color: #dc3545;
}

  .btn:focus {
    box-shadow: none;
  }

  .btn-primary-regular {
    border: 1px solid #3267E3;
    color: #3267E3;
    border-radius: 8px;
    padding: 12px 20px;
  }

  .btn-primary-regular:hover {
    color: #3267E3;
  }

  
.copy-message {
    width: fit-content !important;
    min-width: unset !important;
}
</style>
