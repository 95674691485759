var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "binding",
      },
      [
        _c("binding-error-modal", { attrs: { "error-text": _vm.errorText } }),
        _vm._v(" "),
        _c("button", {
          attrs: {
            id: "bindingErrBtn",
            "data-bs-toggle": "modal",
            "data-bs-target": "#bindingErrModal",
            hidden: "",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modal fade",
            attrs: {
              id: "privacyModal",
              tabindex: "-1",
              "aria-labelledby": "privacyModalLabel",
              "aria-hidden": "true",
              "data-bs-backdrop": "static",
            },
          },
          [
            _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", {
                    staticClass: "privacy-block",
                    domProps: { innerHTML: _vm._s(_vm.setting.privacy) },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "binding-header", style: _vm.setting.header },
          [_vm._v("\n      綁定會員\n    ")]
        ),
        _vm._v(" "),
        _vm.page == 1
          ? [
              _c("div", { staticClass: "binding-content" }, [
                _c("div", { staticClass: "binding-content-text" }, [
                  _vm._v("手機號碼"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "binding-content-phone" }, [
                  _vm._v("\n            09 \n          "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.member.phone,
                        expression: "member.phone",
                      },
                    ],
                    staticStyle: {
                      width: "40%",
                      border: "none",
                      outline: "none",
                      "margin-top": "2px",
                    },
                    attrs: {
                      type: "tel",
                      oninput: "this.value=this.value.slice(0,8)",
                      placeholder: "12345678",
                    },
                    domProps: { value: _vm.member.phone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.member, "phone", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "binding-content-resend btn",
                      style: _vm.setting.button,
                      attrs: {
                        disabled: _vm.member.phone.length < 8 || _vm.isSend,
                      },
                      on: { click: _vm.sendOTP },
                    },
                    [_vm._v("發送驗證碼")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "binding-content-text",
                    staticStyle: { "text-align": "right" },
                    style: [_vm.isSend ? { opacity: "1" } : { opacity: "0" }],
                  },
                  [
                    _c("span", { staticStyle: { color: "#3267e3" } }, [
                      _vm._v(_vm._s(_vm.second)),
                    ]),
                    _vm._v(" 秒後重新發送\n        "),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "binding-content-text" }, [
                  _vm._v("驗證碼"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "binding-content-otp" },
                  _vm._l(_vm.otpValues, function (item, index) {
                    return _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.otpValues[index],
                          expression: "otpValues[index]",
                        },
                      ],
                      key: index,
                      ref: "input" + index,
                      refInFor: true,
                      staticClass: "binding-content-otp-block",
                      attrs: { type: "tel" },
                      domProps: { value: _vm.otpValues[index] },
                      on: {
                        keyup: function ($event) {
                          _vm.handleKeyup(index)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.otpValues, index, $event.target.value)
                        },
                      },
                    })
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "binding-content-otp-hint",
                    style: [_vm.isSend ? { opacity: "1" } : { opacity: "0" }],
                  },
                  [_vm._v("請注意，驗證碼在發送後的 5 分鐘內有效")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "binding-content-btn btn",
                    style: _vm.setting.button,
                    attrs: { disabled: _vm.checkOtpVal },
                    on: { click: _vm.checkOTP },
                  },
                  [_vm._v("\n          送出\n        ")]
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.page == 2
          ? [
              _c("member-info", {
                ref: "MemberInfo",
                attrs: {
                  member: _vm.member,
                  "city-district": _vm.cityDistrict,
                  "is-check": _vm.isCheck,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "binding-content" }, [
                _c("div", { staticClass: "binding-content-privacy" }, [
                  _c(
                    "label",
                    {
                      staticClass: "checkbox-container",
                      attrs: { for: "privacy" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.member.isAgree,
                            expression: "member.isAgree",
                          },
                        ],
                        attrs: { id: "privacy", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.member.isAgree)
                            ? _vm._i(_vm.member.isAgree, null) > -1
                            : _vm.member.isAgree,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.member.isAgree,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.member.isAgree = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.member.isAgree = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.$set(_vm.member, "isAgree", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkmark" }),
                      _vm._v(" "),
                      _vm._m(1),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "binding-content-btn btn",
                    style: _vm.setting.button,
                    attrs: { disabled: !_vm.member.isAgree },
                    on: { click: _vm.checkMemberInfo },
                  },
                  [_vm._v("\n            送出\n          ")]
                ),
              ]),
              _vm._v(" "),
              _c("city-district", {
                on: { "city-district": _vm.getCityDistrict },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.page == 3
          ? [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "binding-content" },
                [
                  _vm.gameUrl && _vm.gameUrl != ""
                    ? [
                        _c(
                          "a",
                          {
                            staticClass: "binding-content-btn btn",
                            style: _vm.setting.button,
                            attrs: { href: _vm.gameUrl },
                          },
                          [_vm._v("開始玩遊戲 ")]
                        ),
                      ]
                    : [
                        _c(
                          "a",
                          {
                            staticClass: "binding-content-btn btn",
                            style: _vm.setting.button,
                            attrs: { href: _vm.addFriendUrl },
                          },
                          [_vm._v("返回至 LINE ")]
                        ),
                      ],
                ],
                2
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header " }, [
      _c(
        "h6",
        { staticClass: "modal-title", attrs: { id: "privacyModalLabel" } },
        [_vm._v("個人資料聲明同意書")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-bs-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("我已閱讀並同意 "),
      _c("a", { attrs: { href: "#privacyModal", "data-bs-toggle": "modal" } }, [
        _vm._v("個人資料聲明同意書"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "binding-content" }, [
      _c("div", { staticClass: "binding-content-success" }, [
        _c("div", [_c("i", { staticClass: "bx bxs-check-circle bx-md" })]),
        _vm._v(" "),
        _c("div", [_vm._v("綁定成功")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }