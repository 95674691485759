<template>
  <div :class="[isDrawing ? 'anime-item' : '']">

    <template v-if="mainColor == '活潑'">
      <img class="item-front" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_front.png" alt="籤筒_前" />
      <img class="item-back" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_back.png" alt="籤筒_後" />
      <img class="item-left" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_left.png" alt="籤筒_左" />
      <img class="item-middle" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_middle.png" alt="籤筒_中" />
      <img class="item-right" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_right.png" alt="籤筒_右" />
    </template>

    <template v-else-if="mainColor == '中性'">
      <img class="item-front" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_front.png" alt="籤筒_前" />
      <img class="item-back" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_back.png" alt="籤筒_後" />
      <img class="item-left" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_left.png" alt="籤筒_左" />
      <img class="item-middle" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_middle.png" alt="籤筒_中" />
      <img class="item-right" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_right.png" alt="籤筒_右" />
    </template>

    <template v-else-if="mainColor == '喜慶'">
      <img class="item-front" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_front.png" alt="籤筒_前" />
      <img class="item-back" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_back.png" alt="籤筒_後" />
      <img class="item-left" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_left.png" alt="籤筒_左" />
      <img class="item-middle" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_middle.png" alt="籤筒_中" />
      <img class="item-right" src="https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_right.png" alt="籤筒_右" />
    </template>
    
  </div>
</template>

<script>

export default {
    name: 'game-anime',
    props: ['isDrawing', 'mainColor']
}
</script>

<style scoped>
img {
    position: absolute;
}




@keyframes rotate-front {
  0%   {transform: translateY(0) rotate(0deg);}
  25%  {transform: translateY(6px) rotate(8deg);}
  50%   {transform: translateY(0) rotate(0deg);}
  75%  {transform: translateY(6px) rotate(8deg);}
  100% {transform: translateY(0) rotate(0deg);}
}
@keyframes rotate-back {
  0%   {transform: translate(0) rotate(0deg);}
  25%  {transform: translate(11px, 7.5px) rotate(8deg);}
  50%  {transform: translate(0) rotate(0deg);}
  75%  {transform: translate(11px, 7.5px) rotate(8deg);}
  100% {transform: translate(0) rotate(0deg);}
}

@keyframes shock-1 {
  0%   {transform: rotate(0);}
  25%  {transform: translate(9px, -5px) rotate(10deg);}
  50%   {transform: rotate(0);}
  75%  {transform: translate(9px, -5px) rotate(10deg);}
  100% {transform: rotate(0);}
}

@keyframes shock-2 {
  0%   {transform: rotate(0);}
  25%  {transform: translate(7px, 8px) rotate(8deg);}
  50%   {transform: rotate(0);}
  75%  {transform: translate(7px, 8px) rotate(8deg);}
  100% {transform: rotate(0);}
}

@keyframes shock-3 {
  0%   {transform: rotate(0);}
  25%  {transform: translate(10px, 10px) rotate(12deg);}
  50%   {transform: rotate(0);}
  75%  {transform: translate(10px, 10px) rotate(12deg);}
  100% {transform: rotate(0);}
}

.anime-item img {
  animation-iteration-count: infinite;
  animation-duration: .6s;
}


.item-front {
    width: 52%;
    top: 52%;
    left: 24%;
    z-index: 10;
    animation-name: rotate-front;
}

.item-back {
    width: 52%;
    top: 49.5%;
    left: 24%;
    z-index: 1;
    animation-name: rotate-back;
}

.item-left,
.item-right {
    width: 30%;
    z-index: 5;
    top: 31%;
}
.item-left {
    left: 23%;
    animation-name: shock-1;
    animation-delay: .4s;
}
.item-right {
    left: 48%;
    animation-name: shock-2;
    animation-delay: .5s;
}

.item-middle {
    width: 25%;
    top: 27%;
    left: 37%;
    z-index: 6;
    animation-name: shock-3;
}

</style>


