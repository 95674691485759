var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.isDrawing ? "anime-item" : ""] },
    [
      _vm.mainColor == "活潑"
        ? [
            _c("img", {
              staticClass: "item-front",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_front.png",
                alt: "籤筒_前",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-back",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_back.png",
                alt: "籤筒_後",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-left",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_left.png",
                alt: "籤筒_左",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-middle",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_middle.png",
                alt: "籤筒_中",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-right",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item1_right.png",
                alt: "籤筒_右",
              },
            }),
          ]
        : _vm.mainColor == "中性"
        ? [
            _c("img", {
              staticClass: "item-front",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_front.png",
                alt: "籤筒_前",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-back",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_back.png",
                alt: "籤筒_後",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-left",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_left.png",
                alt: "籤筒_左",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-middle",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_middle.png",
                alt: "籤筒_中",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-right",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item2_right.png",
                alt: "籤筒_右",
              },
            }),
          ]
        : _vm.mainColor == "喜慶"
        ? [
            _c("img", {
              staticClass: "item-front",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_front.png",
                alt: "籤筒_前",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-back",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_back.png",
                alt: "籤筒_後",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-left",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_left.png",
                alt: "籤筒_左",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-middle",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_middle.png",
                alt: "籤筒_中",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "item-right",
              attrs: {
                src: "https://storage.googleapis.com/c360plus/game/game1_element/game1_item3_right.png",
                alt: "籤筒_右",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }