var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("binding-error-modal", { attrs: { "error-text": _vm.errorText } }),
      _vm._v(" "),
      _c("button", {
        attrs: {
          id: "bindingErrBtn",
          "data-bs-toggle": "modal",
          "data-bs-target": "#bindingErrModal",
          hidden: "",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "membercard" },
        [
          _c(
            "div",
            {
              staticClass: "membercard-header",
              style: [
                _vm.setting.style.background
                  ? { "background-color": _vm.setting.style.background }
                  : {},
                _vm.setting.style.color
                  ? { color: _vm.setting.style.color }
                  : {},
              ],
            },
            [_vm._v("\n        " + _vm._s(_vm.setting.title) + "\n      ")]
          ),
          _vm._v(" "),
          _vm.page == "memberCard"
            ? [
                _c("div", { staticClass: "membercard-content" }, [
                  _vm.setting.image
                    ? _c("div", { staticClass: "membercard-content-img" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.setting.image,
                            alt: "會員卡預覽圖片",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "membercard-content-type" }, [
                    _c(
                      "label",
                      {
                        staticClass: "barcode col-6",
                        attrs: { for: "barcode" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.codeType,
                              expression: "codeType",
                            },
                          ],
                          attrs: {
                            id: "barcode",
                            type: "radio",
                            name: "codeType",
                            value: "barcode",
                          },
                          domProps: {
                            checked: _vm._q(_vm.codeType, "barcode"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.codeType = "barcode"
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(0),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "qrcode col-6", attrs: { for: "qrcode" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.codeType,
                              expression: "codeType",
                            },
                          ],
                          attrs: {
                            id: "qrcode",
                            type: "radio",
                            name: "codeType",
                            value: "qrcode",
                          },
                          domProps: { checked: _vm._q(_vm.codeType, "qrcode") },
                          on: {
                            change: function ($event) {
                              _vm.codeType = "qrcode"
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(1),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.codeType == "barcode",
                          expression: "codeType == 'barcode'",
                        },
                      ],
                      staticClass: "membercard-content-code",
                    },
                    [
                      _c(
                        "barcode",
                        {
                          attrs: {
                            value: _vm.member.memberId,
                            width: "2",
                            height: "60",
                            font: "Roboto",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.member.memberId) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.codeType == "qrcode",
                          expression: "codeType == 'qrcode'",
                        },
                      ],
                      staticClass: "membercard-content-code",
                    },
                    [
                      _c("qrcode", {
                        attrs: { value: _vm.member.memberId, size: 150 },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "12px",
                            "font-family":
                              "-apple-system, BlinkMacSystemFont, 'Roboto', sans-serif",
                          },
                        },
                        [_vm._v(_vm._s(_vm.member.memberId))]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.setting.policyText && _vm.setting.policyText.length > 1
                  ? _c("div", { staticClass: "membercard-privacy" }, [
                      _c("div", {
                        staticClass: "ql-editor",
                        domProps: { innerHTML: _vm._s(_vm.setting.policyHtml) },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "membercard-edit" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary-regular",
                      on: { click: _vm.toEdit },
                    },
                    [
                      _c("i", { staticClass: "bx bx-edit-alt" }),
                      _vm._v(" 編輯會員資料"),
                    ]
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.page == "memberInfo"
            ? [
                _c("member-info", {
                  ref: "MemberInfo",
                  attrs: {
                    member: _vm.editMember,
                    "city-district": _vm.cityDistrict,
                    "is-check": _vm.isCheck,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "edit-content-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      style: _vm.setting.style,
                      on: { click: _vm.checkMemberInfo },
                    },
                    [_vm._v("\n            送出\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn cancel-btn",
                      on: { click: _vm.toCard },
                    },
                    [_vm._v("\n            取消\n          ")]
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.page == "updated"
            ? [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "member-content" }, [
                  _c(
                    "button",
                    {
                      staticClass: "member-content-btn btn",
                      style: _vm.setting.style,
                      on: { click: _vm.toCard },
                    },
                    [_vm._v("返回至會員卡")]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("city-district", { on: { "city-district": _vm.getCityDistrict } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa-solid fa-barcode" }),
      _vm._v(" 條碼"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa-solid fa-qrcode" }),
      _vm._v(" QR Code"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member-content" }, [
      _c("div", { staticClass: "member-content-success" }, [
        _c("div", [_c("i", { staticClass: "bx bxs-check-circle bx-md" })]),
        _vm._v(" "),
        _c("div", [_vm._v("更新成功")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }