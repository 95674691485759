<template>

    <div class="prize-container" v-loading="loading">

      <template v-if="data.status == '已過期'">
        <div class="prize-error text-center">
            <img src="https://storage.googleapis.com/c360plus/prize/finish.png" alt="獎項已結束">
            <div class="prize-error-content text-grey-1">
                <span class="text-bold text-size-20">獎項已結束</span><br/>
                <span class="text-size-16">敬請期待我們的下一個活動</span>
            </div>
        </div>
      </template>

      <template v-else-if="data.status == '序號不足'">
        <div class="prize-error text-center">
            <img src="https://storage.googleapis.com/c360plus/prize/not_enough.png" alt="獎項已發送完畢">
            <div class="prize-error-content text-grey-1">
                <span class="text-bold text-size-20">Sorry</span><br/>
                <span class="text-size-16">獎項已發送完畢</span>
            </div>
        </div>
      </template>

      <template v-else-if="data.status == '無權限'">
        <div class="prize-error text-center">
            <img src="https://storage.googleapis.com/c360plus/prize/failed.png" alt="無法開啟此獎項">
            <div class="prize-error-content text-grey-1">
                <span class="text-bold text-size-20">Sorry</span><br/>
                <span class="text-size-16">無法開啟此獎項</span>
            </div>
        </div>
      </template>

      <template v-else-if="data.status == '外部瀏覽器'">
        <div class="prize-error text-center">
            <img src="https://storage.googleapis.com/c360plus/prize/failed.png" alt="無法開啟此獎項">
            <div class="prize-error-content text-grey-1">
                <span class="text-bold text-size-20">Sorry</span><br/>
                <span class="text-size-16">請使用 LINE 瀏覽器開啟</span>
            </div>
        </div>
      </template>

      <template v-else>
        <div
        class="prize-header text-center text-white text-size-20 text-bold"
        :style="getBackgroundColor"
      >
        <span class="prize-acoount">{{style.lineAccountName}}</span>

        <img
          v-show="data.type == 'LINE POINTS'"
          src="https://storage.googleapis.com/c360plus/prize/LINE_POINTS_liff_header.png"
          alt=""
        />
        <img
          v-show="data.type == '優惠券'"
          src="https://storage.googleapis.com/c360plus/prize/coupon_liff_header.png"
          alt=""
        />
      </div>
      <div class="prize-content">
        <div class="prize-content-title text-size-24 text-bold">
          {{ style.title }}
        </div>
        <div class="prize-content-img" v-if="style.imagePath && style.imagePath != ''">
          <img :src="style.imagePath" alt="獎項圖片" />
        </div>

        <template v-if="data.codeType == '文字型'">
            <div class="prize-content-code">
            <div style="padding: 12px">
                <div class="text-size-12 text-grey-3">您的序號</div>
                <div class="text-grey-1">{{data.code}}</div>
            </div>
            <div class="prize-content-code-copy" @click="copyCouponCode(data.code)">
                <i class="fa-regular fa-copy"></i>
            </div>
            </div>
        </template>
        <button type="button"
          class="btn prize-content-btn text-center text-white text-size-20 text-bold"
          :style="getBackgroundColor"
          @click="toBtnLink"
        >
          {{ style.buttonName }}
        </button>
        <div class="prize-content-note text-grey-1 text-size-14">{{ style.description }}</div>
      </div>
      </template>

    </div>

</template>
<script>

import { getLineToken, getLiffId } from '@/utils/auth'
import { getPrize } from "@/api/prize";
import liff from '@line/liff';

export default {
        computed: {
            getBackgroundColor() {

                if (this.data.type == 'LINE POINTS') {
                    return {'background-color': '#57BD37'}
                } else if (this.data.type == '優惠券') {
                    return {'background-color': '#EC2121'}
                }
                
                return null
            }
        },
        data() {
            return {
                loading: false,
                style: {},
                data: {
                    status: ''
                }
            }
        },
        created() {
            this.liffId = getLiffId()
            this.idToken = getLineToken()
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const pt = urlParams.get('pt')

            if (!pt || pt == '' ) {
                this.data.status = '無權限'
                return
            }

            // if (!liff.isInClient) {
            //     this.data.status = '外部瀏覽器'
            //     return
            // }
            
            this.fetchData(pt)

        },
        mounted() {
    
        },
        methods: {
            async fetchData(pt) {
                let data = {
                    lineToken: this.idToken,
                    jwtToken: pt
                }

                try {
                    let res = await getPrize(this.liffId, data)
                    console.log('res ===', res)
                    if (!res.isSuccess) {
                        this.data.status = '無權限'
                        return
                    }
                    if (res.style) {
                        this.style = res.style
                    }
                    if (res.data) {
                        this.data = res.data
                    }
                } catch (error) {
                    this.data.status = '無權限'
                    return error
                }
                
                

            },
            copyCouponCode (text) {
                navigator.clipboard.writeText(text)
                    .then(() => {
                    this.$message({
                        message: '您已成功複製序號！',
                        offset: 80,
                        customClass: 'copy-message',
                        center: true,
                        type: 'success'
                    })
                })
            },
            toBtnLink() {
                let destination
                
                if (this.data.codeType == '文字型') {

                    if (this.data.type == '優惠券') {
                        destination = this.style.buttonUrl
                    } else {
                        destination = 'https://points.line.me/pointcode'
                    }
                    
                } else if (this.data.codeType == '連結型') {
                    destination = this.data.code
                }
                
                
                if (liff.isInClient()) {
                    liff.openWindow({
                        url: destination,
                        external: this.style.isExternal,
                    });
                    return;
                }
                window.location.replace(destination)
            }
        },
}
</script>

<style scoped>

.prize-container {
    max-width: 768px;
    margin: auto;
}
.prize-header {
    position: relative;
    padding: 14px;
    height: 58px;
    /* background-color: #57BD37; */
}

.prize-header img {
    width: 375px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}

.prize-content {
    padding: 40px 20px;
}

.prize-content-title,
.prize-content-img,
.prize-content-code {
    margin-bottom: 20px;
}


.prize-content-img img {
    width: 100%;
}

.prize-content-code {
    border: 1px #333333 solid;
    border-radius: 8px;
    display: flex;
}

.prize-content-code-copy {
    border-left: 2px #cccccc dashed;
    width: 68px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prize-content-btn {
    /* background-color: #57BD37; */
    width: 100%;
    padding: 12px;
    border-radius: 8px;
}

.prize-content-note {
    margin: 20px 0;
    white-space: pre-wrap;
}

.prize-error img {
    width: 150px;
    margin-top: 150px;
    margin-bottom: 40px;
}

.prize-acoount {
    z-index: 10;
    position: relative;
}

</style>