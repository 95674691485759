import request from '@/utils/request'

export function getGame(liffId, data) {
    return request({
      url: '/liff/v1/' + liffId + '/games',
      method: 'post',
      data: data
    })
}

export function doPlayGame(liffId, data) {
    return request({
      url: '/liff/v1/' + liffId + '/play',
      method: 'post',
      data: data
    })
}

export function doShareGame(data) {
    return request({
      url: '/liff/v1/games/sharing',
      method: 'post',
      data: data
    })
}