<template>
<div>
    <!-- {{member}} -->
    <div class="binding-content">
           <div class="binding-content-text">
             姓名<span class="required" >*</span><br/>
             <input type="text" class="form-control" placeholder="請輸入姓名" v-model="member.officialName"
            :class="status($v.member.officialName)" >
           </div>
        </div>
        <div class="binding-content">
           <div class="binding-content-text">
             手機號碼<span class="required" >*</span><br/>
             <input type="text" class="form-control" disabled v-model=" member.memberId ? member.phone : '09' + member.phone">
           </div>
        </div>

        <div class="binding-content">
           <div class="binding-content-text">
             生日<span class="required" >*</span><br/>
             <date-picker input-class="form-control" :clearable="true" style="width: 100%;" 
                    :range="false" type="date" value-type="YYYY-MM-DD" format="YYYY-MM-DD" placeholder="請選擇"
                    v-model="member.birthday" :class="status($v.member.birthday)" :editable="false" >
                    <i slot="icon-calendar"></i>
                  </date-picker>
           </div>
        </div>

        <div class="binding-content">
           <div class="binding-content-text">
             性別<span class="required" >*</span><br/>
             <select class="form-control form-select" v-model="member.gender" :style="[member.gender == '' ? {color: '#999999'} : {color: '#333333'}]"
             :class="status($v.member.gender)">
              <option value="" disabled>請選擇</option>
              <option value="男">男</option>
              <option value="女">女</option>
              <option value="其他">其他</option>
             </select>
           </div>
        </div>

        <div class="binding-content">
           <div class="binding-content-text">
             Email<span class="required" >*</span><br/>
             <input type="text" class="form-control" placeholder="請輸入電子郵件" v-model="member.email"
             :class="status($v.member.email)">
           </div>
        </div>

        <div class="binding-content">
           <div class="binding-content-text">
             地址<br/>

             <div class="binding-content-district">
              <select class="form-control form-select" v-model="member.addCity" :style="[member.addCity == '' ? {color: '#999999'} : {color: '#333333'}]">
                <option value="">請選擇城市</option>
                <option value="臺北市">臺北市</option>
                <option value="新北市">新北市</option>
                <option value="基隆市">基隆市</option>
                <option value="新竹市">新竹市</option>
                <option value="桃園市">桃園市</option>
                <option value="新竹縣">新竹縣</option>
                <option value="宜蘭縣">宜蘭縣</option>
                <option value="臺中市">臺中市</option>
                <option value="苗栗縣">苗栗縣</option>
                <option value="彰化縣">彰化縣</option>
                <option value="南投縣">南投縣</option>
                <option value="雲林縣">雲林縣</option>
                <option value="高雄市">高雄市</option>
                <option value="臺南市">臺南市</option>
                <option value="嘉義市">嘉義市</option>
                <option value="嘉義縣">嘉義縣</option>
                <option value="屏東縣">屏東縣</option>
                <option value="澎湖縣">澎湖縣</option>
                <option value="花蓮縣">花蓮縣</option>
                <option value="臺東縣">臺東縣</option>
                <option value="金門縣">金門縣</option>
                <option value="連江縣">連江縣</option>
              </select>
              <select style="margin-left: auto;" class="form-control form-select" v-model="member.addDistrict" :style="[member.addDistrict == '' ? {color: '#999999'} : {color: '#333333'}]">
                <option value="">請選擇區域</option>
                <option v-for="(item, index) in selectDistricts" :value="item">{{item}}</option>
              </select>
             </div>
             <input type="text" class="form-control" style="margin-top: 4px" placeholder="詳細地址" v-model="member.addStreet">
           </div>
        </div>
        </div>
</template>

<script>


import BindingErrorModal from "@/components/modal/BindingErrorModal.vue";
import { required } from 'vuelidate/lib/validators'

export default {
    props: ['member', 'cityDistrict', 'isCheck'],
    components: [
        BindingErrorModal
    ],
    watch: {
      'member.addCity': function (newValue, oldValue) {
        this.selectDistricts = []
        this.member.addDistrict = ''
        if (newValue != '') {
          
          this.updateDistrict(newValue)
          
        }
      },
    },
    data() {
        return {
            selectDistricts: []
        }
    },
    validations: {
        member: {
          officialName: {
            required
          },
          birthday: {
            required
          },
          gender: {
            required
          },
          email: {
            required
          },
        },
    },
    mounted() {
      if (this.member.addCity) {
        this.updateDistrict(this.member.addCity)
      }
      
    },
    methods: {
        status(validation) {
            return {
            'is-invalid': (validation.$error || validation.$invalid) && this.isCheck,
            dirty: validation.$dirty,
            }
        },
        updateDistrict(city) {
          
          if (this.cityDistrict) {
            for (let i = 0; i < this.cityDistrict.length; i++) {
              if (this.cityDistrict[i].name != city) {
                continue
              }

              for (let j = 0; j < this.cityDistrict[i].districts.length; j++) {
                this.selectDistricts.push(this.cityDistrict[i].districts[j].name)
              }
            }
          }
        }
    }

}
</script>

<style scoped>
.binding-content {
  padding: 36px 47.5px 0 47.5px;
}

.binding-content-district {
  display: flex;
}

.binding-content-district select {
  width: 49%;
}

</style>
