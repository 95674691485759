<template>
    <div class="modal fade" id="gameFailModal" tabindex="-1" 
        aria-labelledby="gameFailModalLabel" aria-hidden="true" data-bs-backdrop="static">
        
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                
                <div class="content">
                    <template v-if="contentType == 'noLotteryTimes'">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            &times;
                        </button>
                        <div class="text-center">
                            <div class="text-bold">您的遊戲次數已經用完</div>
                            <div class="text-size-14 text-grey-3">感謝您的參與，<br/>
                            敬請期待我們的下一個活動</div>
                        </div>
                    </template>
                    <template v-if="contentType == 'noTodayLotteryTimes'">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            &times;
                        </button>
                        <div class="text-center">
                            <div class="text-bold">您今日的遊戲次數已經用完</div>
                            <div class="text-size-14 text-grey-3">請明天再來挑戰，獲取更多中獎機會！</div>
                        </div>
                    </template>
                    <template v-else-if="contentType == 'noBinding'">
                        <div class="text-center">
                            <div class="text-size-16">完成綁定後即可玩遊戲</div>
                            <button class="btn btn-primary binding-btn text-bold" @click="toBinding()">前往綁定</button>
                        </div>
                    </template>
                    <template v-else-if="contentType == 'lineBrowserError'">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            &times;
                        </button>
                        <div class="text-center">
                            <div class="text-size-16">請使用 LINE APP 完成分享</div>
                        </div>
                    </template>
                    <template v-else-if="contentType == 'internalError'">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            &times;
                        </button>
                        <div class="text-center">
                            <div class="text-bold">Oops...出了一點狀況</div>
                            <div class="text-size-14 text-grey-3">請稍後再試</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
      </div>
</template>

<script>
import liff from '@line/liff';
export default {
    props: ['contentType', 'bindingUrl'],
    methods: {
        toBinding() {

            if (!this.bindingUrl) {
                return
            }

            let gameUrl = window.location.href
            let bindingUrl = this.bindingUrl
            let redirectUrl = ''
            if (bindingUrl.indexOf('?') != -1) {
                redirectUrl = '&rg=' + gameUrl
            } else {
                redirectUrl = '?rg=' + gameUrl
            }

            window.location.replace(bindingUrl + redirectUrl)
            
        }
    }
}
</script>

<style scoped>
.modal-dialog {
    width: 80%;
    margin: auto;
}
.modal-dialog .close {
    position: absolute;
    top: -50px;
    left: calc(50% - 18px);
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    font-size: 28px;
    width: 36px;
    font-weight: 600;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.modal-content {
    border-radius: 16px;
} 
.content {
    padding: 20px;
    text-align: center;
}
.binding-btn {
    width: 70%;
    border-radius: 20px;
    margin-top: 20px;
}


</style>
