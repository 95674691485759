var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.status == "isMember"
        ? [
            _c("member-card", {
              attrs: { "liff-id": _vm.liffId, member: _vm.member },
            }),
          ]
        : _vm.status == "isNotMember"
        ? [
            _c("member-register", {
              attrs: {
                "liff-id": _vm.liffId,
                "add-friend-url": _vm.addFriendUrl,
                "game-url": _vm.gameUrl,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }