var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "prize-container",
    },
    [
      _vm.data.status == "已過期"
        ? [_vm._m(0)]
        : _vm.data.status == "序號不足"
        ? [_vm._m(1)]
        : _vm.data.status == "無權限"
        ? [_vm._m(2)]
        : _vm.data.status == "外部瀏覽器"
        ? [_vm._m(3)]
        : [
            _c(
              "div",
              {
                staticClass:
                  "prize-header text-center text-white text-size-20 text-bold",
                style: _vm.getBackgroundColor,
              },
              [
                _c("span", { staticClass: "prize-acoount" }, [
                  _vm._v(_vm._s(_vm.style.lineAccountName)),
                ]),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.type == "LINE POINTS",
                      expression: "data.type == 'LINE POINTS'",
                    },
                  ],
                  attrs: {
                    src: "https://storage.googleapis.com/c360plus/prize/LINE_POINTS_liff_header.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.type == "優惠券",
                      expression: "data.type == '優惠券'",
                    },
                  ],
                  attrs: {
                    src: "https://storage.googleapis.com/c360plus/prize/coupon_liff_header.png",
                    alt: "",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "prize-content" },
              [
                _c(
                  "div",
                  { staticClass: "prize-content-title text-size-24 text-bold" },
                  [_vm._v("\n      " + _vm._s(_vm.style.title) + "\n    ")]
                ),
                _vm._v(" "),
                _vm.style.imagePath && _vm.style.imagePath != ""
                  ? _c("div", { staticClass: "prize-content-img" }, [
                      _c("img", {
                        attrs: { src: _vm.style.imagePath, alt: "獎項圖片" },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.codeType == "文字型"
                  ? [
                      _c("div", { staticClass: "prize-content-code" }, [
                        _c("div", { staticStyle: { padding: "12px" } }, [
                          _c(
                            "div",
                            { staticClass: "text-size-12 text-grey-3" },
                            [_vm._v("您的序號")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-grey-1" }, [
                            _vm._v(_vm._s(_vm.data.code)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "prize-content-code-copy",
                            on: {
                              click: function ($event) {
                                _vm.copyCouponCode(_vm.data.code)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa-regular fa-copy" })]
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn prize-content-btn text-center text-white text-size-20 text-bold",
                    style: _vm.getBackgroundColor,
                    attrs: { type: "button" },
                    on: { click: _vm.toBtnLink },
                  },
                  [_vm._v("\n      " + _vm._s(_vm.style.buttonName) + "\n    ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "prize-content-note text-grey-1 text-size-14",
                  },
                  [_vm._v(_vm._s(_vm.style.description))]
                ),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prize-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/prize/finish.png",
          alt: "獎項已結束",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "prize-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("獎項已結束"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [
          _vm._v("敬請期待我們的下一個活動"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prize-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/prize/not_enough.png",
          alt: "獎項已發送完畢",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "prize-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("Sorry"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [_vm._v("獎項已發送完畢")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prize-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/prize/failed.png",
          alt: "無法開啟此獎項",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "prize-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("Sorry"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [_vm._v("無法開啟此獎項")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prize-error text-center" }, [
      _c("img", {
        attrs: {
          src: "https://storage.googleapis.com/c360plus/prize/failed.png",
          alt: "無法開啟此獎項",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "prize-error-content text-grey-1" }, [
        _c("span", { staticClass: "text-bold text-size-20" }, [
          _vm._v("Sorry"),
        ]),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-size-16" }, [
          _vm._v("請使用 LINE 瀏覽器開啟"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }