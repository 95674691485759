<template>
  <div class="wrap" v-loading="loading">
    
    <binding-error-modal :error-text="errorText"></binding-error-modal>
      <button id="bindingErrBtn" data-bs-toggle="modal" data-bs-target="#bindingErrModal" hidden></button>

      <div class="membercard">
        <div
          class="membercard-header"
          :style="[
            setting.style.background
              ? { 'background-color': setting.style.background }
              : {},
            setting.style.color ? { color: setting.style.color } : {},
          ]"
        >
          {{ setting.title }}
        </div>

        <!-- <pre>{{member}}</pre> -->
        <template v-if="page == 'memberCard'">
        <div class="membercard-content">

          <!-- {{member}} -->
          <div class="membercard-content-img" v-if="setting.image">
            <img :src="setting.image" alt="會員卡預覽圖片" />
          </div>
          <div class="membercard-content-type">
            <label class="barcode col-6" for="barcode">
              <input
                id="barcode"
                type="radio"
                name="codeType"
                v-model="codeType"
                value="barcode"
              />
              <span><i class="fa-solid fa-barcode"></i>&nbsp;條碼</span>
            </label>
            <label class="qrcode col-6" for="qrcode">
              <input
                id="qrcode"
                type="radio"
                name="codeType"
                v-model="codeType"
                value="qrcode"
              />
              <span><i class="fa-solid fa-qrcode"></i>&nbsp;QR Code</span>
            </label>
          </div>
          <div
            class="membercard-content-code"
            v-show="codeType == 'barcode'"
          >
            <barcode :value="member.memberId" width="2" height="60" font="Roboto">
              {{ member.memberId }}
            </barcode>
          </div>
          <div class="membercard-content-code" v-show="codeType == 'qrcode'">
            <qrcode :value="member.memberId" :size="150"></qrcode>
            <div style="margin-top: 12px; font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;">{{ member.memberId }}</div>
          </div>
        </div>
        <div
          class="membercard-privacy"
          v-if="setting.policyText && setting.policyText.length > 1"
        >
          <div class="ql-editor" v-html="setting.policyHtml"></div>
        </div>

        <div class="membercard-edit" >
          <button class="btn btn-primary-regular" @click="toEdit"><i class='bx bx-edit-alt'></i>&nbsp;編輯會員資料</button>
        </div>
        </template>

        <template v-if="page == 'memberInfo'">
          <member-info ref="MemberInfo" :member="editMember" :city-district="cityDistrict" :is-check="isCheck"></member-info>

          <div class="edit-content-btn">
            <button
              class="btn"
              :style="setting.style"
              @click="checkMemberInfo">
              送出
            </button>
      
            <button
              class="btn cancel-btn"
              @click="toCard">
              取消
            </button>
          </div>
        </template>

        <template v-if="page == 'updated'">
          <div class="member-content">
            <div class="member-content-success">
              <div><i class='bx bxs-check-circle bx-md'></i></div>
              <div>更新成功</div>
            </div>
          </div>
          <div class="member-content">
          <button class="member-content-btn btn" :style="setting.style" @click="toCard"
              >返回至會員卡</button>
          </div>
        </template>

      </div>

      <city-district @city-district="getCityDistrict" />

    
  </div>
</template>

<script>

import $ from "jquery";
import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import { getMemberSetting, doUpdateMember } from "@/api/member"; 
import BindingErrorModal from "@/components/modal/BindingErrorModal.vue";
import MemberInfo from './MemberInfo.vue';
import { getLineToken } from "@/utils/auth"; 
import CityDistrict from "@/components/CityDistrict.vue";
export default {
  name: 'member-card',
  props: ['liffId', 'member'],
  data: function () {
    return {
      page: 'memberCard',
      idToken: null,
      loading: false,
      cityDistrict: [],
      isCheck: false,
      errorText: '',
      setting: {
        title: "",
        style: {
          background: "#FFFFFF",
          color: "#FFFFFF",
        },
        image: "",
        policyHtml: "",
        policyText: "",
        isDefault: "barcode",
        file: {
          name: "",
          error: false,
          data: null,
        },
      },
      codeType: 'barcode',
      editMember: {}
    };
  },
  watch: {
      'page': function (newValue, oldValue) {
        setTimeout(function () {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
          });
        },2);
      },
  },
  components: {
    barcode: VueBarcode,
    qrcode: QrcodeVue,
    MemberInfo: MemberInfo,
    CityDistrict: CityDistrict,
    BindingErrorModal: BindingErrorModal
  },
  created() {
      this.fetchData()
      this.idToken = getLineToken()
  },
  methods: {
    getCityDistrict(data) {
        this.cityDistrict = data
    },
    fetchData() {

      getMemberSetting(this.liffId).then((res) => {
        console.log(res)
        if (res.setting) {
          let setting = JSON.parse(res.setting)

          // 預設藍色
            if (!setting.style.background) {
              setting.style.background = '#3267E3'
            }

          this.setting = setting

          if (this.setting.isDefault) {
            this.codeType = this.setting.isDefault
          }
        } else {
          this.setting.style.background = '#3267E3'
        }
      });
    },
    checkMemberInfo() {

        this.isCheck = true
        if (this.$refs.MemberInfo.$v.$invalid || this.$refs.MemberInfo.$v.$error) {
          return
        }
        

        // 檢查email格式
        let email = this.editMember.email
        if (!this.isEmail(email)) {
          this.errorText = 'Email格式錯誤，請再次輸入'
          $('#bindingErrBtn').click()
          return
        }

        if (this.isNotEmpty(this.editMember.addCity) || this.isNotEmpty(this.editMember.addDistrict) || this.isNotEmpty(this.editMember.addStreet)) {
          
          let hasEmpty = !(this.isNotEmpty(this.editMember.addCity) && this.isNotEmpty(this.editMember.addDistrict) && this.isNotEmpty(this.editMember.addStreet))
          if (hasEmpty) {
            this.errorText = '請填寫完整地址資訊'
            $('#bindingErrBtn').click()
            return
          }
        }

        let member = this.member
        member.officialName = this.editMember.officialName,
        member.phone = this.editMember.phone,
        member.birthday = this.editMember.birthday,
        member.gender = this.editMember.gender,
        member.email = this.editMember.email,
        member.addCity = this.editMember.addCity,
        member.addDistrict = this.editMember.addDistrict,
        member.addStreet = this.editMember.addStreet,
        member.settingLiffId = this.liffId
        let data = {
          lineToken: this.idToken,
          bindingInfo: member
        }

        console.log(data)

        this.loading = true
        doUpdateMember(this.liffId, data).then((res) => {
          this.loading = false
          if (res.isSuccess) {
            this.page = 'updated'
          }
        })

      },
      isEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true
        } else {
            return false
        }
      },
      isNotEmpty(value) {
        return value != null && value != ''
      },
      toCard() {
        this.editMember = {}
        this.page = 'memberCard'
      },
      toReload() {
        window.location.reload();
      },
      toEdit() {
        this.editMember = {
          officialName: this.member.officialName,
          phone: this.member.phone,
          birthday: this.member.birthday,
          gender: this.member.gender,
          email: this.member.email,
          addCity: this.member.addCity,
          addDistrict: this.member.addDistrict,
          addStreet: this.member.addStreet,
          memberId: this.member.memberId
        }
        this.page = 'memberInfo'
      }
  }
};
</script>


<style scoped>

.wrap {
  max-width: 768px;
  margin: auto;
}
.membercard {
  padding-bottom: 15px;
}

.membercard-header {
    font-size: 20px;
    font-weight: 500;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
        
}

.membercard-content-img img {
  width: 100%;
}

.membercard-content-type {
  display: flex;
  text-align: center;
  padding: 0 15px;
}

.membercard-content-type label {
  padding: 9.5px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
}

.membercard-content-type input {
  display: none;
}

.membercard-content-type span {
  width: 100%;
  padding: 9.5px 0;
  display: inline-block;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  color: #999999;
}

.membercard-content-type input:checked + span {
  color: #3267e3;
  border-bottom: 2px solid #3267e3;
}

.membercard-content-code {
  padding: 20px 30px;
  text-align: center;
}


.membercard-privacy {
  margin: auto;
  width: 345px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 12px 10px;
}

.membercard-edit {
  margin: 28px auto;
  text-align: center;
}

.edit-content-btn {
  padding: 0 47.5px;
  margin-top: 80px;
  width: 100%;
  text-align: center;
}

.cancel-btn {
  margin-top: 12px;
  color: #333333;
  border: 1px solid #333333;
}

.edit-content-btn button {
  width: 100%;
}

.member-content {
  padding: 36px 47.5px 0 47.5px;
}

.member-content-success {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #3267E3;
  margin: 100px 0;
}

.member-content-success i {
  color: #22C55E;
}

.member-content-btn {
  text-align: center;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
}


</style>