import axios from 'axios'
import { getAuthToken, setToken, setLiffId } from '@/utils/auth'
import liff from '@line/liff';

const token = getAuthToken()
// create an axios instance
const service = axios.create({
  baseURL: process.env.SERVER_DOMAIN + '/base', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
})


service.interceptors.request.use(
  config => {
    // do something before request is sent
    
    // if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // let currentLineAccountId = store.getters.currentLineAccount.id
    // config.headers['SocialAccount'] = currentLineAccountId
    config.headers['Content-Type'] = 'application/json'
    config.headers['Authorization'] = token
    config.headers['Environment'] = process.env.NODE_ENV
    // }

    // config.headers['x-frame-options'] = "ALLOW-FROM SAMEDOMAIN, exacttarget.com"
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * If you want to get http information such as headers or status
     * Please return  response => response
  */
  /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
  */
  response => {
    return response.data
  },
  error => {
    if (error.response.status == 400 || error.response.status == 404) {
      if (error.response.data) {
        if (error.response.data.msg.indexOf('token is expired') != -1) {
          if (liff.isLoggedIn()) {
            liff.logout();

            liff.login();
            return
          }

          
        }
      }
    }
    return Promise.reject(error)
  }
)

export default service