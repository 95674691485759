import request from '@/utils/request'

export function getMemberSetting(data) {
  return request({
    url: '/liff/v1/' + data + '/member/info',
    method: 'get',
  })
}

export function getMember(liffId, token) {
  return request({
    url: '/liff/v1/lineusers/' + liffId + '/member/' + token,
    method: 'get',
  })
}

export function getBindingSetting(data) {
  return request({
    url: '/liff/v1/' + data + '/member/binding',
    method: 'get',
  })
}

export function doSendOtp(liffId, data) {
  return request({
    url: '/api/v1/verifications/' + liffId + '/otp',
    method: 'post',
    data: data
  })
}

export function doVerify(liffId, data) {
  return request({
    url: '/api/v1/verifications/' + liffId ,
    method: 'post',
    data: data
  })
}

export function doBinding(liffId, data) {
  return request({
    url: '/liff/v1/lineusers/' + liffId + '/binding',
    method: 'post',
    data: data
  })
}

export function doUpdateMember(liffId, data) {
  return request({
    url: '/liff/v1/lineusers/' + liffId + '/member',
    method: 'patch',
    data: data
  })
}
  