<template>
    <div class="modal fade" id="bindingErrModal" tabindex="-1" 
        aria-labelledby="bindingErrModallLabel" aria-hidden="true"
        data-bs-backdrop="static" >
        
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="error-content">
                    <div class="error-text">
                        {{errorText}}
                    </div>
                    <div>
                        <button class="error-btn btn" data-bs-dismiss="modal">了解</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
</template>

<script>

export default {
    props: ['errorText']
}
</script>

<style scoped>

.modal-content {
    width: 80%;
    margin: auto;
}
.error-content {
    text-align: center;
    padding: 12px 20px;
}

.error-text {
    padding: 36px 0;
}

.error-btn {
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #3267E3;
    color: #fff;
}
</style>
